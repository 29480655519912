export const languageConstants = {
  andromeda: ["en", "es", "jp", "pt"],
  bolerus: ["es", "gl"],
  casiopea: ["en", "es", "ca"],
  draco: ["es", "eu", "fr", "en"],
  indus: ["es", "eu", "fr", "en"],
  eudora: ["es", "gl", "en"],
  gemini: ["es", "gl", "en"],
  petisgo: ["es", "gl"],
  tiivii: ["en", "es", "gl"],
};
