import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertNotification from "@components/GUI/Alert/Alert";
import {
  addFavoriteService,
  deleteFavoriteService,
} from "../../../../app/src/services/services";
import { TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";

import "./Favorite.css";

function Favorite(props) {
  const [actualizado, setActualizado] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    !!props.isFavorite ? setIsFavorite(true) : setIsFavorite(false);
  }, [props]);

  const removeFavCallback = (response) => {
    if (!response.favorites.includes(props.id)) {
      setIsFavorite(false);
      setActualizado(true);
      setTimeout(() => {
        setActualizado(false);
      }, 1000);
    }
  };

  const removeFav = () => {
    deleteFavoriteService(
      TENANT,
      props.username,
      props.id,
      props.token,
      removeFavCallback
    );
  };

  const addFavCallback = (response) => {
    const favorites = response.data.favorites;
    if (favorites.includes(props.id)) {
      setIsFavorite(true);
      setActualizado(true);
      setTimeout(() => {
        setActualizado(false);
      }, 1000);
    }
  };

  const addFav = () => {
    addFavoriteService(
      TENANT,
      props.username,
      props.id,
      props.token,
      addFavCallback
    );
  };

  return (
    <>
      {isFavorite && (
        <div
          className={`FavButton ${props.largeVersion && "FavButtonLarge"}`}
          onClick={() => removeFav()}
        >
          <div className="IconContainer">
            <ion-icon name={"heart"} style={{ color: "#dc3545" }} size="large" />
          </div>
          {props.version === 2 && <div>{t("ficha.anadidoafavoritos")}</div>}
        </div>
      )}
      {!isFavorite && (
        <div
          className={`FavButton ${props.largeVersion && "FavButtonLarge"}`}
          onClick={() => addFav()}
        >
          <div className="IconContainer">
            <ion-icon name="heart-outline" size="large" />
          </div>
          {props.version === 2 && <div>{t("ficha.anadirfavorito")}</div>}
        </div>
      )}
      {actualizado && (
        <AlertNotification
          type="success"
          showtitle={false}
          text={
            isFavorite
              ? t("ficha.successaddfavorite")
              : t("ficha.successremovefavorite")
          }
          isPlaylist={props.isPlaylist}
        />
      )}
    </>
  );
}

export default Favorite;
