import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import "react-multi-carousel/lib/styles.css";
import Spinner from "react-bootstrap/Spinner";
import { useKeycloak } from "@react-keycloak/web";

import {
    TRANSITION_DURATION,
    TYPE_BANNER_BANNER,
    TYPE_BANNER_BANNER_LARGE,
    TYPE_BANNER_POSTER,
    TYPE_BANNER_PROGRESS,
} from "../PlaylistConstants";
import {
    responsive,
    responsivelarge,
} from "@detail/Pages/DetailPage/CarrouselStyle";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import defaultBannerLarge from "@app/Resources/Images/default_banner_LARGE.jpg";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import "@detail/Pages/DetailPage/CarrouselStyle.css";
import {
    DATA_LOADING_STATUS_LOADED,
    TVG,
    WIDGET_ACTION_PLAY,
    WIDGET_TYPE_BANNER_CLICK,
    WIDGET_TYPE_BANNER_SLIDER,
    WIDGET_TYPE_FEATURED_SLIDER,
} from "../../../../../app/src/Utils/Constants";
import { evaluateItemClass, LIMIT_ITEMS } from "./utils";
import VideoType from "../VideoType";
import {
    actionTypeContainer,
    renderImageUrl,
} from "../../../../../app/src/Utils/utils";
import {
    URL_CONTENT,
    URL_LOGIN,
} from "../../../../../app/src/Components/Routes/Location";
import VideoRating from "../VideoRating";
import {
    MULTITENANT_CONSTANTS,
    TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import Favorite from "../Favorite";

const FilterWidgetComponent = (props) => {
    const {
        key,
        className,
        style,
        title,
        hasMoreContents,
        id,
        typeBanner,
        playlist,
        playSpinner,
        clickCallback,
        controller,
        event,
        turnOnLoading,
        trigger,
        titleColor,
        contentId,
        statusLoader,
        origin,
    } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [limitNumberCards, setLimitNumberCards] = useState(LIMIT_ITEMS);
    const [overOnCard, setOverOnCard] = useState(false);
    const tenantIsFreeToPlay = useSelector(
        (state) => state.commonState.tenantStyles.isFreeToPlay
    );

    const minResolutionToCarousel = 14000;

    useEffect(() => {
        if (window.innerWidth < minResolutionToCarousel) {
            setLimitNumberCards(3);
        }
    }, [playlist]);

    const minimumItems =
        +playlist?.length < limitNumberCards ? " BlockCarousel" : " ";

    const createImage = (image, title) => {
        return <img className="PlaylistItemImage" src={image} alt={title} />;
    };

    const getTranslatedTitle = (item) => {
        // Intenta encontrar una traducción que coincida con el idioma actual
        const translation = item.translations?.find(
          (translation) => translation.language === currentLanguage
        );
        
        // Si hay una traducción en el idioma actual, úsala; de lo contrario, usa el título por defecto
        return translation?.title || item.title;
      };
      

    const getImage = (w) => {
        switch (typeBanner.toUpperCase()) {
            case TYPE_BANNER_POSTER:
                return createImage(
                    !!w.poster
                        ? w.poster
                        : !!w.banner
                            ? w.banner
                            : defaultBannerLarge,
                    w.title
                );
            case TYPE_BANNER_BANNER:
            case WIDGET_TYPE_FEATURED_SLIDER:
            case WIDGET_TYPE_BANNER_SLIDER:
                return createImage(
                    !!w.banner
                        ? w.banner
                        : !!w.poster
                            ? w.poster
                            : defaultBanner,
                    w.title
                );
            case TYPE_BANNER_PROGRESS:
                return (
                    <>
                        {createImage(
                            !!w.banner
                                ? w.banner
                                : !!w.poster
                                    ? w.poster
                                    : defaultBanner,
                            w.title
                        )}
                        <div style={{ marginTop: "-11px" }}>
                            <ProgressBar now={w.progress} className="BarProgress" />
                        </div>
                    </>
                );
            case WIDGET_TYPE_BANNER_CLICK:
                return createImage(
                    !!w.backgroundimage
                        ? w.backgroundimage
                        : defaultBanner,
                    w.title
                );
            default:
                return createImage(
                    !!w.backgroundimage
                        ? w.backgroundimage
                        : !!w.banner
                            ? w.banner
                            : !!w.background
                                ? w.background
                                : defaultBanner,
                    w.title
                );
        }
    };

    const { keycloak, initialized } = useKeycloak();

    const isLogin = () => {
        return initialized && keycloak?.authenticated;
    };

    return (
        <>
            {origin === "ORIGIN_SECTIONS" && (
                <div className="SpaceOriginSection"></div>
            )}
            <div className="PlaylistContainer" key={key}>
                <div className="CarouselContainer">
                    <div className={"Playlist " + className} style={style}>
                        <div className={"Titulo"} style={{ display: "flex" }}>
                            <p style={{ width: "auto", color: titleColor }}>
                                {statusLoader === DATA_LOADING_STATUS_LOADED && title}
                            </p>
                            {hasMoreContents && (
                                <Link
                                    to={URL_CONTENT + "/widget/" + id}
                                    style={{
                                        padding: "0 0 0 15px",
                                        display: "flex",
                                        width: "auto",
                                    }}
                                    className="Titulo button-link-secondary"
                                >
                                    <div className="ShowMoreText">{t("ficha.showmore")}</div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        style={{ margin: "10px 0 0 5px" }}
                                    />
                                </Link>
                            )}
                        </div>
                        {!!playlist?.length && (
                            <Carousel
                                swipeable={true}
                                draggable
                                showDots={false}
                                responsive={
                                    typeBanner.toUpperCase() === TYPE_BANNER_BANNER_LARGE
                                        ? responsivelarge
                                        : responsive
                                }
                                infinite={true}
                                autoPlay={false}
                                keyBoardControl={false}
                                transitionDuration={TRANSITION_DURATION}
                                customTransition="transform 1000ms ease-in-out 0s"
                                centerMode={false}
                                partialVisible={true}
                                containerClass={
                                    "carousel-container " +
                                    typeBanner +
                                    " " +
                                    minimumItems +
                                    " PlaylistCollection"
                                }
                                dotListClass="custom-dot-list-style"
                                itemClass={"CardContainer"}
                                arrows
                            >
                                {playlist?.map((w, i) => {
                                    return (
                                        <div
                                            className={evaluateItemClass({
                                                i,
                                                overOnCard,
                                                playlist,
                                                itemClass: "PlaylistItemContainer",
                                            })}
                                            key={w.id}
                                            onMouseEnter={() => setOverOnCard(i)}
                                            onMouseLeave={() => setOverOnCard(false)}
                                        >
                                            <Link
                                                to={{
                                                    pathname:
                                                        !tenantIsFreeToPlay &&
                                                            !isLogin() &&
                                                            trigger === WIDGET_ACTION_PLAY
                                                            ? URL_LOGIN
                                                            : actionTypeContainer(trigger)?.ROUTE +
                                                            "/" +
                                                            w.id,
                                                }}
                                                onClick={() => contentId !== w.id && turnOnLoading()}
                                            >
                                                <div
                                                    className="PlaylistItem"
                                                    onClick={
                                                        clickCallback ? () => clickCallback(w.id) : ""
                                                    }
                                                    key={w.title}
                                                >
                                                    {getImage(w)}
                                                    {!!w.tags?.includes(TVG) && (
                                                        <img
                                                            className="iconTVG"
                                                            src={iconTVG}
                                                            alt={"iconTVG"}
                                                        />
                                                    )}
                                                    <VideoType subtitle={w} />
                                                </div>
                                            </Link>
                                            <div className="PlaylistItemContaner">
                                                <Link
                                                    to={{
                                                        pathname:
                                                            !tenantIsFreeToPlay &&
                                                                !isLogin() &&
                                                                trigger === WIDGET_ACTION_PLAY
                                                                ? URL_LOGIN
                                                                : actionTypeContainer(trigger)?.ROUTE +
                                                                "/" +
                                                                w.id,
                                                    }}
                                                    onClick={() => contentId !== w.id && turnOnLoading()}
                                                >
                                                    <div className="PlaylistItemTitle-Default">
                                                        {getTranslatedTitle(w)}
                                                    </div>
                                                    <div className={"ListIcons"}>
                                                        {MULTITENANT_CONSTANTS[TENANT].showRating ? (
                                                            <VideoRating w={w} />
                                                        ) : (
                                                            MULTITENANT_CONSTANTS[TENANT].showSubtitle && (
                                                                <div className="PlaylistItemSubtitlePerRating">
                                                                    {w.subtitle}
                                                                </div>
                                                            )
                                                        )}
                                                        <div className="PlaylistItemGenre">
                                                            {!!w.category && (
                                                                <span className="Xenero">
                                                                    {w.category.code}
                                                                </span>
                                                            )}
                                                            {!!w.seasons && (
                                                                <span className="Temporadas">
                                                                    {t("ficha.seasons", { seasons: w.seasons })}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="PlayFavContainer">
                                                    <Link
                                                        className="linkSpace"
                                                        to={{
                                                            pathname:
                                                                !tenantIsFreeToPlay &&
                                                                    !isLogin() &&
                                                                    trigger === WIDGET_ACTION_PLAY
                                                                    ? URL_LOGIN
                                                                    : actionTypeContainer(trigger)?.ROUTE +
                                                                    "/" +
                                                                    w.id,
                                                        }}
                                                    >
                                                        <div className="boton-play BotonVer button-focused">
                                                            {!tenantIsFreeToPlay &&
                                                                !isLogin() &&
                                                                trigger === WIDGET_ACTION_PLAY
                                                                ? t("ficha.login")
                                                                : t(
                                                                    "ficha." +
                                                                    actionTypeContainer(trigger)?.TEXT
                                                                )}
                                                        </div>
                                                    </Link>
                                                    {w.canFav && (
                                                        <Favorite
                                                            controller={controller}
                                                            events={event}
                                                            id={w.id}
                                                            isFavorite={w.isFav}
                                                            isPlaylist={true}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Carousel>
                        )}
                    </div>
                    <div className={"Alert"}>
                        <NotificationContainer />
                    </div>
                    {playSpinner && (
                        <div className="SpinnerContainer">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FilterWidgetComponent;
