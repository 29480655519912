import {
  DISPLAY_SESSION_EXPIRED_MODAL,
  HIDE_SESSION_EXPIRED_MODAL,
  LOAD_TENANT_STYLES,
  TURN_OFF_LOADING_ACTION,
  TURN_ON_LOADING_ACTION,
  STORE_PRODUCT_URL,
  TOGGLE_PASSWORD_MODE,
  DISPLAY_ON_BOARDING,
  SELECT_MENU_SECTION,
  SECTIONS_MENU,
  SAVE_USER_DATA,
  SELECT_MENU_SCREEN_ID,
  SELECTED_SECTION,
  SAVE_TENANT_LANGUAGES,
  SAVE_SEARCH_TEXT,
  SAVE_SPONSORS,
  SAVE_INTERFACE_URLS,
  SAVE_LEGAL,
  SAVE_RULES,
  CONTENT_HASH,
  CONTENT_TYPE,
  USER_URL_AVATAR,
  SAVE_LANGUAGE_SELECTED, SAVE_TENANT_SHOW_PREVIEWS,
} from "./actionTypes";

export const turnOnLoadingActionCreator = () => ({
  type: TURN_ON_LOADING_ACTION,
});

export const turnOffLoadingActionCreator = () => ({
  type: TURN_OFF_LOADING_ACTION,
});

export const displaySessionExpiredModalActionCreator = () => ({
  type: DISPLAY_SESSION_EXPIRED_MODAL,
});

export const hideSessionExpiredModalActionCreator = () => ({
  type: HIDE_SESSION_EXPIRED_MODAL,
});

export const loadTenantStylesActionCreator = (data) => ({
  type: LOAD_TENANT_STYLES,
  data,
});

export const storeProductURLActionCreator = (data) => ({
  type: STORE_PRODUCT_URL,
  data,
});

export const togglePasswordModeActionCreator = () => ({
  type: TOGGLE_PASSWORD_MODE,
});

export const displayOnBoardingActionCreator = (data) => ({
  type: DISPLAY_ON_BOARDING,
  data,
});

export const selectedSectionActionCreator = (data) => ({
  type: SELECTED_SECTION,
  data,
});

export const selectMenuSection = (data) => ({
  type: SELECT_MENU_SECTION,
  data,
});

export const selectMenuScreenId = (data) => ({
  type: SELECT_MENU_SCREEN_ID,
  data,
});

export const storeSectionsMenuActionCreator = (data) => ({
  type: SECTIONS_MENU,
  data,
});

export const saveUserDataActionCreator = (data) => ({
  type: SAVE_USER_DATA,
  data,
});

export const saveTenantLanguageActionCreator = (data) => ({
  type: SAVE_TENANT_LANGUAGES,
  data,
});

export const saveTenantShowPreviews = (data) => ({
    type: SAVE_TENANT_SHOW_PREVIEWS,
    data,
    });

export const saveSponsorsActionCreator = (data) => ({
  type: SAVE_SPONSORS,
  data,
});

export const saveInterfaceUrlsActionCreator = (data) => ({
  type: SAVE_INTERFACE_URLS,
  data,
});

export const saveSearchTextActionCreator = (data) => ({
  type: SAVE_SEARCH_TEXT,
  data,
});

export const saveLegalActionCreator = (data) => ({
  type: SAVE_LEGAL,
  data,
});

export const saveTenantRulesActionCreator = (data) => ({
  type: SAVE_RULES,
  data,
});

export const saveContentHashActionCreator = (data) => ({
  type: CONTENT_HASH,
  data,
});

export const saveContentTypeActionCreator = (data) => ({
  type: CONTENT_TYPE,
  data,
});

export const saveUserURLAvatarActionCreator = (data) => ({
  type: USER_URL_AVATAR,
  data,
});

export const saveLanguageSelected = (data) => ({
  type: SAVE_LANGUAGE_SELECTED,
  data,
});
