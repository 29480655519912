import React, { useState, useEffect, useRef } from "react";
import "../Catalogo.css";
import { Col } from "reactstrap";
import { getSectionName } from "../../../../../app/src/Utils/utils";

const SubsectionsMenuComponent = ({
  section,
  defaultSections,
  selectNodeChildren,
}) => {
  const [subsection, setSubsection] = useState(defaultSections[0].name);
  const [displaySubsectionMenu, setDisplaySubsectionMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleDisplaySectionMenu = () => {
    setDisplaySubsectionMenu((prev) => !prev);
  };

  // Gestión del cerrado del menú al hacer click fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleDisplaySectionMenu();
      }
    };

    if (displaySubsectionMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displaySubsectionMenu]);

  const handleChangeSubSection = (value) => {
    toggleDisplaySectionMenu();
    setSubsection(value);
    selectNodeChildren(value);
  };

  useEffect(() => {
    if (defaultSections[0].name !== subsection) {
      setSubsection(defaultSections[0].name);
    }
  }, [defaultSections]);

  return (
    <>
      {section && !!defaultSections?.length && (
        <Col xs={6} sm={4} lg={4} className="OrderMenuContainer">
          <div className="OrderMenu" ref={menuRef}>
            <div
              className="MenuCatalogueContainer"
              onClick={toggleDisplaySectionMenu}
            >
              <span className="MenuCatalogue sectionsMenuTitle">
                {getSectionName(defaultSections.find(node => node.name === subsection))}
              </span>
              <span className={"IconMenuCatalogue"}>
                <ion-icon name="chevron-down-outline" />
              </span>
            </div>
            {displaySubsectionMenu && (
              <div className="MenuDesplegableOrder">
                {defaultSections?.map((node) => (
                  <div
                    type="button"
                    className="MenuSubsectionOrder"
                    key={node?.name}
                    onClick={() => handleChangeSubSection(node?.name)}
                  >
                    <span className="button-text">{getSectionName(node)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Col>
      )}
    </>
  );
};

export default SubsectionsMenuComponent;
