import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  TRANSITION_DURATION,
  TYPE_BANNER_BANNER_LARGE,
} from "../PlaylistConstants";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";
import {
  evaluateItemClass,
  LIMIT_ITEMS,
} from "./utils";
import {
  actionTypeContainer,
  generateSlug,
  getWidgetTitle,
  getContentCodeByLanguage,
  renderImageUrl,
} from "../../../../../app/src/Utils/utils";
import { URL_SCREEN } from "../../../../../app/src/Components/Routes/Location";

const ListScreenGridComponent = ({
  key,
  className,
  style,
  title,
  hasMoreContents,
  id,
  typeBanner,
  playlist,
  playSpinner,
  trigger,
  titleColor,
  turnOnLoading,
  contentId = null,
}) => {
  const [overOnCard, setOverOnCard] = useState(false);
  const { t } = useTranslation();

  const minimumItems =
    +playlist?.length < LIMIT_ITEMS ? " BlockGrid" : " ";

  const getPageId = (w) => {
    return w?.screen?.id ? w.screen.id : w.screen;
  };

  return (
    <div className="MyCustomWidgetContainer custom-grid-widget" key={key}>
    <div className="PlaylistContainer GridContainer GroupWidget">
      <div className={"Playlist " + className} style={style}>
        <div className="Titulo" style={{ display: "flex" }}>
          <p style={{ width: "auto", color: titleColor }}>{title}</p>
          {hasMoreContents && (
            <Link
              to={`${actionTypeContainer(trigger).ROUTE}/${generateSlug(
                title
              )}/${id}`}
              style={{ padding: "0px 0px 20px 0px", display: "flex", width: "120px" }}
              className="Titulo button-link-secondary"
            >
              <div className="ShowMoreText">{t("ficha.showmore")}</div>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ margin: "4px 0 0 5px" }}
              />
            </Link>
          )}
        </div>
        {playlist?.length > 0 && (
          <div
            className={
              "grid-container PlaylistCollection " +
              typeBanner +
              " " +
              minimumItems
            }
          >
            {playlist?.map((w, i) => {
              return (
                <div
                  className={evaluateItemClass({
                    i,
                    overOnCard,
                    playlist,
                    itemClass: "PlaylistItemContainer",
                  })}
                  key={i}
                  onMouseEnter={() => setOverOnCard(i)}
                  onMouseLeave={() => setOverOnCard(false)}
                >
                  {!!getPageId(w) && (
                    <Link
                      to={`${actionTypeContainer(trigger).ROUTE}/${generateSlug(
                        w.title
                      )}/${getPageId(w)}`}
                      onClick={() => contentId !== w.id && turnOnLoading()}
                      className="PlaylistItem"
                      key={w.id + "-" + w.backgroundimage + w.color}
                    >
                      <div className="PlaylistItem">
                        {!!w.backgroundimage ? (
                          <img
                            className="PlaylistItemImage"
                            alt={w.title}
                            src={w.backgroundimage}
                          />
                        ) : (
                          <img
                            className="PlaylistItemImage"
                            src={defaultBanner}
                            alt={w.title}
                          />
                        )}
                        <div className="PlaylistItemTitleGrid">
                          {getWidgetTitle(w)}
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {playSpinner && (
        <div className="SpinnerContainer">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
     </div>
    </div>
  );
};

export default ListScreenGridComponent;
