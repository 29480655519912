import React from "react";
import MenuLanguagesComponent from "./MenuLanguagesComponent";
import { useTranslation } from "react-i18next";
import { SHOW_LENGUAJE } from "../../../../../Utils/Constants";
import "./LanguagesComponent.css";

export const LanguagesComponent = ({ show, handelShow, renderMobileVersion }) => {
  const { t } = useTranslation();

  return (
    <div className={"languages"}>
      <div className="tooltip-container">
        <div onClick={() => handelShow(SHOW_LENGUAJE)} className={"LanguageMenuIconContainer"}>
          <ion-icon name="globe-outline" id={"LanguageMenuIcon"} />
        </div>
        <span className="tooltip-text">{t("catalogue.idioms")}</span>
      </div>
      <MenuLanguagesComponent
        renderMobileVersion={renderMobileVersion}
        handleShowLanguagesMenu={() => handelShow(SHOW_LENGUAJE)}
        showLanguagesMenu={show}
      />
    </div>
  );
};