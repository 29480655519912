import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { turnOffLoadingActionCreator } from "../../../../app/src/actions/commonActions";
import "./CheckoutComponent.css";
import {
  URL_PLAYER,
  URL_VIDEODETAIL,
} from "../../../../app/src/Components/Routes/Location";
import iconParty from "../../../../app/src/Resources/Images/row2.svg";
import unlock from "../../../../app/src/Resources/Images/unlock.png";
import { getFileService } from "../../../../app/src/services/services";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import UserProfile from "../../../../user/src/Pages/Usuario/UserProfile";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {getToken} from "../../../../app/src/Utils/utils";

const CheckoutComponent = ({ success }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const loading = useSelector((state) => state.commonState.loading);
  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  const userData = useSelector((state) => state.commonState.userData);

  const [userProfile, setUserProfile] = useState({ ...userData });

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getFileCallback = (response) => {
    const formattedModel = {
      ...userProfile,
      avatar: { url: response.data.data.url },
    };
    setUserProfile(formattedModel);
    turnOffLoading();
  };

  useEffect(() => {
    const userToken = getToken(keycloak);
    if (userToken && userData.avatar) {
      getFileService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        userData.avatar,
        userToken,
        getFileCallback
      );
    }
  }, [keycloak]);

  useEffect(() => {
    if (loading) {
      turnOffLoading();
    }
  }, [loading]);

  const handleRedirectToDetail = () => {
    history.push(URL_VIDEODETAIL + "/" + id);
  };

  const handleRedirectToPlayer = () => {
    history.push(URL_PLAYER + "/" + id);
  };

  return (
    <div
      className={"CheckoutPage"}
      style={{
        "background-image": "url(" + tenantStyles?.backgroundRegister + ")",
      }}
    >
      {success && (
        <h1 className={"paymentTitle"}>
          <img src={unlock} alt={"unlock"} />
          {t("products.checkout.payment")}
        </h1>
      )}

      <div className={"modalCheckout"}>
        <div className={"Logo-checkout"} />
        {success ? (
          <>
            <div>
              <h2 className={"title"}>
                {t("products.checkout.congratulations")}
              </h2>
            </div>
            <p>{UserProfile.userName}</p>
            <div className={"confirmContent"}>
              <div className="IconoConfirm">
                <div className={"IconParty"}>
                  <img src={iconParty} alt={"party"} />
                </div>
                <img src={UserProfile.avatar?.url} alt="Avatar" />
                <div className={"IconParty"}>
                  <img
                    className={"rotateImage"}
                    src={iconParty}
                    alt={"party"}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <h2 className={"title"}>{t("products.checkout.fail")}</h2>
            </div>
          </>
        )}
        {success ? (
          <>
            <h3>{t("products.checkout.paymentConfirm")}</h3>
            <p>{t("products.checkout.success")}</p>
          </>
        ) : (
          <p>{t("products.checkout.cancel")}</p>
        )}
        <div className="ReportOptionButtonContainer">
          <button
            onClick={handleRedirectToDetail}
            className="CheckoutButton button-focused"
          >
            {t("global.button.go.to.detail")}
          </button>
          {success && (
            <button
              onClick={handleRedirectToPlayer}
              className="CheckoutButton button-focused"
            >
              {t("global.button.go.to.player")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithAuthorization(CheckoutComponent);
