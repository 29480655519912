import React, { useState } from "react";
import "../BarraSuperior.css";
import { useTranslation } from "react-i18next";
import { URL_SEARCH } from "../../../../../Components/Routes/Location";
import { updateUserDataService } from "../../../../../services/services";
import { useKeycloak } from "@react-keycloak/web";
import { TENANT, MULTITENANT_CONSTANTS } from "../../../../../Resources/Multitenant/tenantConstants";
import { languageConstants } from "../../../../../languageConstants/languageConstants";
import { useSelector, useDispatch } from "react-redux";
import {getToken, setHtmlLang} from "../../../../../Utils/utils";
import { saveUserDataActionCreator } from "../../../../../actions/commonActions";
import {LOCAL_STORAGE_KEY__LOCALE} from "../../../../../Utils/Constants";

const MenuLanguagesComponent = ({ handleShowLanguagesMenu, showLanguagesMenu, renderMobileVersion }) => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [languageSelected, setLanguageSelected] = useState(i18n.language);

  const availableLanguagesRedux = useSelector((state) => state.commonState.tenantLanguages);
  const availableLanguages = availableLanguagesRedux || languageConstants[TENANT];

  const JP = "jp";

  const updateUserDataCallback = (response) => {
    dispatch(saveUserDataActionCreator(response));
  };

  const handleChangeLanguage = (l) => {
    const username = keycloak?.tokenParsed?.preferred_username;
    const userToken = getToken(keycloak);
    const userLanguage = { language: l };
    !!username ?
      updateUserDataService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        username,
        userToken,
        updateUserDataCallback,
        null,
        userLanguage,
      ) : dispatch(saveUserDataActionCreator(userLanguage));

    setLanguageSelected(l);
    localStorage.setItem(LOCAL_STORAGE_KEY__LOCALE, l);
    i18n.changeLanguage(l);
    setHtmlLang(l);
    handleShowLanguagesMenu();
  };
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };

  return (
    <div
      className={`${languageSelected === JP ? "MenuDesplegable_JP" : "MenuDesplegable"} ${
        showLanguagesMenu && "active"
      } ${isSearchSection() && renderMobileVersion ? "belowTheSearch" : ""}`}
    >
      {availableLanguages?.length > 0 &&
        availableLanguages.map((lang, i) => (
          <div
            key={i}
            className={"Button-Language " + (i18n.language && i18n.language.startsWith(lang) ? "Selected" : "")}
            onClick={() => handleChangeLanguage(lang)}
          >
            <span className={i18n.language === JP ? "verticalText" : ""}>{t(`lang.min.${lang}`)}</span>
          </div>
        ))}
    </div>
  );
};

export default MenuLanguagesComponent;
